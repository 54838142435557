import axios from "axios";
import { addDays, format } from "date-fns";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Seo from "../components/seo";

function CheckoutSuccess(props) {
  if (!props.location.state && typeof window !== "undefined") {
    navigate("/");
  }

  if (!props.location.state) {
    props.location.state = {
      amountCharged: 0,
      cartContents: 0,
      tax: 0,
      email: "",
      transactionData: {
        order_id: 0,
      },
    };
  }

  useEffect(() => {
    if (
      props.location.state.transactionData?.order_id &&
      !localStorage.getItem(
        `checkout-success-${props.location.state.transactionData?.order_id}`
      )
    ) {
      localStorage.setItem(
        `checkout-success-${props.location.state.transactionData?.order_id}`,
        true
      );

      axios.post(`/.netlify/functions/conversion`, {
        email: props.location.state.email,
        checkoutInfo: props.location.state.checkoutInfo,
        cartTotals: props.location.state.cartTotals,
        discount: sessionStorage.getItem("discounts"),
        cartContents: props.location.state.cartContents,
        callInfo: props.location.state.callInfo,
        sourceUrl: sessionStorage.getItem("bpd-first-page"),
        referrer: sessionStorage.getItem("bpd-referrer"),
        value: props.location.state.amountCharged
          ? +props.location.state.amountCharged?.substr(1)
          : 0,
        orderId: props.location.state.transactionData?.order_id,
        gclid: localStorage.getItem("bpd-gclid"),
        fbclid: localStorage.getItem("bpd-fbclid"),
        utm_source: localStorage.getItem("bpd-utm_source"),
        utm_medium: localStorage.getItem("bpd-utm_medium"),
        utm_campaign: localStorage.getItem("bpd-utm_campaign"),
        msclkid: localStorage.getItem("bpd-msclkid"),
      });

      ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TRACKING_CODE, {
        testMode: process.env.GOOGLE_ANALYTICS_TESTING_MODE,
      });
      ReactGA.plugin.require("ecommerce");

      props.location.state.cartContents.map((cartItem) => {
        ReactGA.plugin.execute("ecommerce", "addItem", {
          name: cartItem.title,
          price: cartItem.salePrice ? +cartItem.salePrice : +cartItem.basePrice,
          brand: process.env.COMPANY_NAME,
          sku: cartItem.url.replace(/\//g, ""),
          category: cartItem.url.includes("window-well-covers")
            ? "Window Well Covers"
            : "Accessories",
          variant: cartItem.attributes?.length ? cartItem.title : "",
          quantity: cartItem.quantity,
        });
        return true;
      });

      ReactGA.plugin.execute("ecommerce", "addTransaction", {
        id: props.location.state.transactionData?.order_id,
        affiliation: process.env.COMPANY_NAME,
        revenue: +props.location.state.amountCharged.substr(1),
        tax: +props.location.state.tax.substr(1),
        shipping: 0,
      });
      ReactGA.plugin.execute("ecommerce", "send");
      ReactGA.plugin.execute("ecommerce", "clear");
    }
  }, [
    props.location.state.amountCharged,
    props.location.state.callInfo,
    props.location.state.cartContents,
    props.location.state.cartTotals,
    props.location.state.checkoutInfo,
    props.location.state.email,
    props.location.state.tax,
    props.location.state.transactionData?.order_id,
  ]);

  useEffect(() => {
    const estimatedDeliveryDate = format(addDays(new Date(), 14), "yyyy-MM-dd");
    // const products = props.location.state.cartContents.map((cartSku) => {
    //   return {
    //     sku: cartSku.url.replace(/\//g, ""),
    //   };
    // });
    function renderOptIn() {
      window.gapi.load("surveyoptin", () => {
        window.gapi.surveyoptin.render({
          merchant_id: process.env.GOOGLE_REVIEWS_MERCHANT_ID,
          order_id: props.location.state.transactionData?.order_id,
          email: props.location.state.email,
          delivery_country: "US",
          estimated_delivery_date: estimatedDeliveryDate,
          // products: products,
        });
      });
    }

    if (typeof window.gapi !== "undefined") {
      renderOptIn();
    } else {
      window.renderOptIn = renderOptIn;
    }

    return () => {
      window.renderOptIn = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Function to check if the page was reloaded
    const handleRefresh = () => {
      if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
        navigate("/");
      }
    };

    window.addEventListener("load", handleRefresh);

    // Cleanup function
    return () => window.removeEventListener("load", handleRefresh);
  }, []);

  return (
    <Layout>
      <Helmet>
        <script
          src="https://apis.google.com/js/platform.js?onload=renderOptIn"
          async
          defer
        ></script>
      </Helmet>
      <Seo title="Thank you for your order!" />
      <div className="flex flex-wrap">
        <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8 text-grey-darker">
          <h1 className="mb-4">Thank you for your order!</h1>
          <h3 className="mb-2">
            Order ID: {process.env.ORDER_ID_PREFIX}
            {props.location.state
              ? props.location.state.transactionData?.order_id
              : "NA"}
          </h3>
          <p>
            Amount Charged:{" "}
            {props.location.state ? props.location.state.amountCharged : "NA"}
          </p>
          <hr className="pt-4 border-b border-grey-light" />
          <h3 className="pt-8 pb-3">What happens from here</h3>
          <ul>
            <li className="py-2">
              If you chose pickup, we will contact you when your covers are
              ready for pickup. If you chose delivery we will contact you when
              we are ready to head your way.
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}
export default CheckoutSuccess;
